import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'codecraft-web-project-footer',
  imports: [CommonModule],
  template: `
    <footer>
      <div style="text-align: center; padding-left: 1rem; flex-grow: 1;">
        <span>
          Created by
          <a
            *ngIf="username; else displayNameOnly"
            style="color: white;"
            [href]="urlbase + '/users/' + username"
            >{{ displayname }}</a
          >
          <ng-template #displayNameOnly>{{ displayname || '' }}</ng-template>
        </span>
      </div>
      <div
        style="text-align: center; flex-grow: 2; padding-left: 1rem; padding-right: 1rem;"
      >
        <a style="color: white;" [href]="urlbase + '/web/' + projectid">
          <strong>{{ projectname }}</strong>
        </a>
      </div>
      <div style="text-align: center; padding-right: 1rem; flex-grow: 1;">
        <span>Powered by </span>
        <a [href]="urlbase + '/web'">
          <img
            style="height: 1.5rem; margin: 3px; vertical-align: middle;"
            src="https://app.codecraftworks.com/assets/img/codecraft-works-white-gears-animated.svg"
          />
        </a>
      </div>
    </footer>
  `,
  styles: [
    `
      footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #211c38;
        line-height: 3rem;
        color: white;
        display: flex;
        z-index: 2147483647;
        flex-wrap: wrap;
        flex-direction: row !important;
        align-items: stretch;
        border-top: 3px solid;
        border-image: linear-gradient(
          90deg,
          rgba(234, 28, 38, 1) 0%,
          rgba(58, 107, 235, 1) 50%,
          rgba(253, 182, 52, 1) 100%
        );
        border-image-slice: 1;
        font-family: Roboto, 'Helvetica Neue', Arial, -apple-system,
          BlinkMacSystemFont, 'Segoe UI', sans-serif, 'Apple Color Emoji',
          'Segoe UI Emoji', 'Segoe UI Symbol';
      }
    `,
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class WebProjectFooterComponent {
  private _username = '';
  private _displayname = '';
  private _urlbase = '';
  private _projectid = '';
  private _projectname = '';

  @Input()
  set username(value: string) {
    if (value !== this._username) {
      this._username = value;
    }
  }
  get username(): string {
    return this._username;
  }

  @Input()
  set displayname(value: string) {
    if (value !== this._displayname) {
      this._displayname = value;
    }
  }
  get displayname(): string {
    return this._displayname;
  }

  @Input()
  set urlbase(value: string) {
    if (value !== this._urlbase) {
      this._urlbase = value;
    }
  }
  get urlbase(): string {
    return this._urlbase;
  }

  @Input()
  set projectid(value: string) {
    if (value !== this._projectid) {
      this._projectid = value;
    }
  }
  get projectid(): string {
    return this._projectid;
  }

  @Input()
  set projectname(value: string) {
    if (value !== this._projectname) {
      this._projectname = value;
    }
  }
  get projectname(): string {
    return this._projectname;
  }
}
